.adminForm,
.infoWrapper {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 1fr max-content;
  border: 1px solid #ddd;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 5px;

  .adminLabel {
    display: inline-block;
    margin-bottom: 10px;
  }

  .adminSubmitButton {
    margin-left: 5px;
    height: 40px;
    width: 150px;
    background-color: #0052cc;
    border-radius: 0%;

    &:hover {
      background-color: #003ea0;
    }
  }

  .adminInput {
    height: 40px;
    font-size: 1em;
  }
}

.infoWrapper {
  .editButton {
    margin-left: 5px;
    height: 40px;
    width: 150px;
    background-color: #0052cc;
    border-color: transparent;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      background-color: #003ea0;
    }
  }

  .urlInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 0;
    padding: 0 10px;
    width: 100%;
  }
}
