$sand: #ebe5d9;
$brown: #974d39;
$mist: #eeeeee;
$white: #ffffff;
$eggshell: #fafafa;

@font-face {
  font-family: canelaLight;
  font-display: swap;
  src: url("/assets/fonts/canela_light.woff2") format("woff2");
}

@font-face {
  font-family: canelaLightItalic;
  font-display: swap;
  src: url("/assets/fonts/canela_light_italic.woff2") format("woff2");
}

$mobile: "only screen and (min-device-width: 320px)  and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2)";
$tablet: "(min-width: 768px) and (max-device-width: 1024px)   ";
$tablet-portrait: "(min-width: 768px) and (max-device-width: 1024px)  and (orientation: portrait)  ";
$tablet-landscape: "(min-width: 768px) and (max-device-width: 1024px)  and (orientation: landscape)  ";
$tablet-below: "(max-width: 767px)";
$desktop: "(min-width: 1024px)";
$desktop-below: "(max-width: 1023px)";
$desktop-large: "(min-width: 1280px) and (min-height: 768px) ";
$desktop-very-large: "screen and (min-width: 1700px)";
