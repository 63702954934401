.navbar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 50px;
  display: flex;
  flex-direction: column;
  z-index: 20;
  overflow: hidden;

  &:hover {
    width: 200px;

    .background {
      transform: translateX(0);
    }
  }
}

.background {
  position: absolute;
  background: #fff;
  top: 0;
  left: 0;
  height: 100vh;
  width: 200px;
  z-index: -1;
  transition: transform 0.1s ease-out;
  transform: translateX(-150px);
}

.logo {
  text-align: center;
  padding: 10px;

  a {
    display: block;
  }

  img {
    display: block;
    width: 30px;
    height: 30px;
  }

  border-bottom: 1px solid #ddd;
}

.menu {
  margin-top: 7px;
  font-size: 14px;
  overflow-x: hidden;
  line-height: 14px;
}

.menuItem {
  padding: 7px 0;

  img {
    width: 30px;
    height: 30px;
  }

  &:hover {
    background-color: #f2f2f2;
  }
}

.menuItemInner {
  display: flex;
  color: #000;
  padding-left: 10px;
  align-items: center;
}

.label {
  margin-left: 10px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s 0.1s ease-out;

  .navbar:hover & {
    opacity: 1;
  }
}
