@import "../../styles/variables.scss";

.wrapper {
  background-color: white;
  border-radius: 4px;
  box-shadow: none;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .wrapper {
    border: 1px solid $mist;
  }
}

.header {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid $mist;
}

.content {
  padding: 1rem;
  position: relative;
}

.heading {
}

.footer {
}
